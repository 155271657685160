import makeApiRequest from "./httpMainService";
import { useStore } from "../store";
import { router } from "../index";
import Notiflix from "notiflix";

const proxyUrl = "/api.php";
const testUrl = "https://api.demo.carsalesconnect.pl/api/";
const test = false;

export default {
  enteredLogin: async () => {
    const store = useStore();
    const commonConfig = {
      headers: {
        Accept: test ? "application/json" : "*",
        "Content-Type": test ? "application/json" : "text/plain",
      },
    };
    const commonData = {
      params: {
        login: store.login,
        csc_client_id: test ? "3" : store.cscClientId,
      },
      endpoint: "send-password",
    };

    const url = test ? `${testUrl}${commonData.endpoint}` : proxyUrl;
    const requestData = test ? commonData.params : commonData;

    try {
      const response = await makeApiRequest(
        "post",
        url,
        requestData,
        commonConfig
      );

      if (response.status === 200) {
        store.set("loginError", false);
        store.set("hideLoginButton", false);
        store.set("goAnotherStep", true);
        store.set("passwordExpirationDate", response.data.expirationDate);
      } else if (response.response.status === 429) {
        store.set("loginError", false);
        store.set("hideLoginButton", false);
        store.set("tooManyAttempts", true);
      } else if ([404, 405].includes(response.response.status)) {
        store.set("tooManyAttempts", false);
        store.set("hideLoginButton", false);
        store.set("loginError", true);
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  enteredPassword: async function () {
    const store = useStore();
    const commonData = {
      params: {
        login: store.login,
        password: store.enteredPassword,
        csc_client_id: test ? "3" : store.cscClientId,
      },
      endpoint: "login",
    };

    const url = test ? `${testUrl}${commonData.endpoint}` : proxyUrl;
    const requestData = test ? commonData.params : commonData;

    try {
      const response = await makeApiRequest("post", url, requestData);

      if (response.status === 200) {
        store.set("passwordError", false);
        localStorage.setItem("Token", response.data.token);
        store.set("token", response.data.token);
        store.set("loginInProcess", true);
        await this.tokenActive("/main", "/login", null);
      } else {
        store.set("passwordError", true);
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  loadOffers: async function (temporaryToken) {
    const store = useStore();
    const config = {
      headers: {
        Authorization: temporaryToken
          ? `Bearer ${temporaryToken}`
          : `Bearer ${localStorage.getItem("Token")}`,
        Accept: "*",
        "Content-Type": "application/json",
      },
    };
    const currentDate = new Date();
    const clientId = test ? "3" : store.cscClientId;
    const url = test
      ? testUrl + `v1/data/${clientId}/offers`
      : proxyUrl + `?endpoint=v1/data/${clientId}/offers`;
    try {
      const response = await makeApiRequest("get", url, null, config);
      if (response.status === 200) {
        const targetDate = new Date(
          response.data.data.offers[0].expirationDate
        );
        if (response.data.data.offers.length === 1) {
          if (currentDate > targetDate) {
            localStorage.removeItem("Token");
            store.set("noOffersCommunicate", true);
          } else {
            store.set(
              "defaultOfferID",
              localStorage.getItem("lastSeenOffer") ||
                response.data.data.default_offer_id
            );
            store.set("offers", response.data.data.offers);
            store.set("offerLoaded", true);
            this.loadComponents(temporaryToken);
            if (store.loginInProcess) {
              this.createLoginEvent();
            }
          }
        } else if (
          response.data.data.offers.length !== 0 &&
          response.data.data.offers.length !== 1
        ) {
          let offers;
          offers = response.data.data.offers.filter(
            (offer) => new Date(offer.expirationDate) > currentDate
          );
          if (offers[0].offerId) {
            store.set("defaultOfferID", offers[0].offerId);
            store.set("offers", offers);
            store.set("offerLoaded", true);
            this.loadComponents(temporaryToken);
            if (store.loginInProcess) {
              this.createLoginEvent();
            }
          } else {
            localStorage.removeItem("Token");
            store.set("noOffersCommunicate", true);
          }
        }
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  loadComponents: async function (temporaryToken) {
    const store = useStore();
    const config = {
      headers: {
        Authorization: temporaryToken
          ? `Bearer ${temporaryToken}`
          : `Bearer ${localStorage.getItem("Token")}`,
        Accept: "*",
        "Content-Type": "application/json",
      },
    };
    const url = test
      ? testUrl + `v1/data/components/${store.defaultOfferID}`
      : proxyUrl + `?endpoint=v1/data/components/${store.defaultOfferID}`;

    try {
      const response = await makeApiRequest("get", url, null, config);

      if (response.status === 200) {
        store.set("data", response.data.data);

        if (!store.disableAddMainOffer && response.data.data.vehicles[0]) {
          store.addMainOffer(response.data.data.vehicles[0]);
        }
        store.set("dataLoaded", true);
        store.set("loginInProcess", false);
        setTimeout(() => {
          store.set("dataLoaded", false);
          store.set("disableAddMainOffer", false);
        }, 1000);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },
  createLoginEvent: async function () {
    const data = {
      endpoint: test
        ? null
        : `v1/event/login/create/${useStore().defaultOfferID}`,
      params: {
        login_method_id: useStore().loginMethodMail ? "2" : "1",
      },
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
        Accept: test ? undefined : "*",
        "Content-Type": test ? undefined : "application/json",
      },
    };

    const url = test
      ? testUrl + `v1/event/login/create/${useStore().defaultOfferID}`
      : proxyUrl;
    if (!test) {
      try {
        const response = await makeApiRequest("post", url, data, config);
        localStorage.setItem("firstLoginEvent", response.data.eventId);
        localStorage.setItem("defaultLoginEvent", response.data.eventId);
        useStore().set("firstLoginEvent", response.data.eventId);
        useStore().set("defaultLoginEvent", response.data.eventId);
      } catch (error) {
        console.log(error);
      }
    }
  },
  postEvent: async () => {
    if (!test && useStore().blockTracking === false) {
      const data = {
        endpoint: `v1/event/login/update/time/${localStorage.getItem(
          "defaultLoginEvent"
        )}`,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      };
      if (localStorage.getItem("defaultLoginEvent")) {
        try {
          const response = await makeApiRequest("post", proxyUrl, data, config);
          if (response.data.loginSessionEventId) {
            localStorage.setItem(
              "defaultLoginEvent",
              response.data.loginSessionEventId
            );
            useStore().set(
              "defaultLoginEvent",
              response.data.loginSessionEventId
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  },
  testEvent: async () => {
    if (test && useStore().blockTracking === false) {
      console.log("test");
      const data = {
        endpoint: `v1/test/login/update/time/${localStorage.getItem(
          "defaultLoginEvent"
        )}`,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      };
      try {
        const response = await makeApiRequest("post", proxyUrl, data, config);
        if (response.data.loginSessionEventId) {
          localStorage.setItem(
            "defaultLoginEvent",
            response.data.loginSessionEventId
          );
          useStore().set(
            "defaultLoginEvent",
            response.data.loginSessionEventId
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  trackSection: async (name, arr) => {
    if (!test && useStore().blockTracking === false) {
      const data = {
        params: {
          vehicle_ids: arr,
        },
        endpoint: `v1/event/${name}/store/${localStorage.getItem(
          "defaultLoginEvent"
        )}`,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      };
      try {
        const response = await makeApiRequest("post", proxyUrl, data, config);
        if (response.data.loginSessionEventId) {
          localStorage.setItem(
            "defaultLoginEvent",
            response.data.loginSessionEventId
          );
          useStore().set(
            "defaultLoginEvent",
            response.data.loginSessionEventId
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  trackFiles: async (name, id) => {
    if (!test && useStore().blockTracking === false) {
      const data = {
        params: {
          file_ids: [id],
        },
        endpoint: `v1/event/${name}/store/${localStorage.getItem(
          "defaultLoginEvent"
        )}`,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      };

      try {
        const response = await makeApiRequest("post", proxyUrl, data, config);
        if (response.data.loginSessionEventId) {
          localStorage.setItem(
            "defaultLoginEvent",
            response.data.loginSessionEventId
          );
          useStore().set(
            "defaultLoginEvent",
            response.data.loginSessionEventId
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  trackChangeCar: async (name, id) => {
    if (!test && useStore().blockTracking === false) {
      const data = {
        params: {
          vehicle_ids: [id],
        },
        endpoint: `v1/event/${name}/store/${localStorage.getItem(
          "defaultLoginEvent"
        )}`,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      };
      if (localStorage.getItem("defaultLoginEvent") !== null) {
        try {
          const response = await makeApiRequest("post", proxyUrl, data, config);
          if (response.data.loginSessionEventId) {
            localStorage.setItem(
              "defaultLoginEvent",
              response.data.loginSessionEventId
            );
            useStore().set(
              "defaultLoginEvent",
              response.data.loginSessionEventId
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  },
  trackChangeOffer: async (newOfferId) => {
    if (!test && useStore().blockTracking === false) {
      const store = useStore();
      const data = {
        endpoint: `v1/event/login/switch/${newOfferId}/${localStorage.getItem(
          "defaultLoginEvent"
        )}`,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
          Accept: "*",
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await makeApiRequest("post", proxyUrl, data, config);
        localStorage.setItem("defaultLoginEvent", response.data.eventId);
        store.set("defaultLoginEvent", response.data.eventId);
      } catch (error) {
        console.log(error);
      }
    }
  },
  advisorClicked: async (name, id) => {
    if (!test && useStore().blockTracking === false) {
      const data = {
        params: {
          advisor_ids: [id],
        },
        endpoint: `v1/event/${name}/store/${localStorage.getItem(
          "defaultLoginEvent"
        )}`,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      };
      try {
        const response = await makeApiRequest("post", proxyUrl, data, config);
        if (response.data.loginSessionEventId) {
          localStorage.setItem(
            "defaultLoginEvent",
            response.data.loginSessionEventId
          );
          useStore().set(
            "defaultLoginEvent",
            response.data.loginSessionEventId
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  downloadFile: async (
    proxyUrl,
    fileType,
    mimeType,
    extension,
    description
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
      responseType: "blob",
    };
    try {
      const response = await makeApiRequest("get", proxyUrl, null, config);
      const blob = new Blob([response.data], { type: mimeType });
      const downloadName = `${description.replace(" ", "")}.${extension}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = downloadName;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error);
    }
  },

  tokenActive: async function (
    mainDestination,
    tokenExpiredDestination,
    query
  ) {
    const store = useStore();
    store.set("loginError", false);
    store.set("tooManyAttempts", false);
    const url = test
      ? testUrl +
        "check-token-expiration" +
        `?token=${localStorage.getItem("Token")}`
      : proxyUrl +
        `?endpoint=check-token-expiration` +
        `?token=${localStorage.getItem("Token")}`;

    try {
      if (query && query.temporaryToken) {
        const config = {
          headers: {
            Authorization: `Bearer ${query.temporaryToken}`,
            Accept: "*",
            "Content-Type": "application/json",
          },
        };
        const commonData = {
          endpoint: "v1/token/assign-ip",
        };
        useStore().set("blockTracking", true);
        useStore().set("query", query);
        const response = await makeApiRequest(
          "get",
          proxyUrl +
            `?endpoint=check-token-expiration` +
            `?token=${query.temporaryToken}`,
          null,
          null,
          tokenExpiredDestination
        );
        if (response.data.isValid) {
          store.set("allowComponentsToLoad", true);
          localStorage.removeItem("Token");
          router.push(mainDestination);
          this.loadOffers(query.temporaryToken);
          const postEntry = await makeApiRequest(
            "post",
            proxyUrl,
            commonData,
            config
          );
        } else {
          this.clearLocalStorageAndRedirect(tokenExpiredDestination);
        }

        return response;
      } else if (
        query === null ||
        query === undefined ||
        query.temporaryToken === undefined
      ) {
        useStore().set("blockTracking", false);
        useStore().set("temporaryToken", null);
        const response = await makeApiRequest(
          "get",
          url,
          null,
          null,
          tokenExpiredDestination
        );
        if (response.data.isValid) {
          store.set("allowComponentsToLoad", true);
          router.push(mainDestination);
          this.loadOffers();
        } else {
          this.clearLocalStorageAndRedirect(tokenExpiredDestination);
        }

        return response;
      }
    } catch (error) {
      this.clearLocalStorageAndRedirect(tokenExpiredDestination);
    }
  },

  clearLocalStorageAndRedirect: function (destination) {
    localStorage.removeItem("Token");
    localStorage.removeItem("dataToCompare");
    localStorage.removeItem("firstLoginEvent");
    localStorage.removeItem("defaultLoginEvent");
    localStorage.removeItem("lastSeenOffer");
    useStore().set("tokenIsValid", false);
    router.push(destination);
  },
  getAssets: async () => {
    if (!test) {
      try {
        const response = await makeApiRequest("get", "/getStaticAssets.php");
        if (response.status === 200) {
          useStore().set("footerLogo", response.data.footerLogo);
          useStore().set("logo", response.data.clientLogo);
          useStore().set("cscClientId", response.data.cscClientId);
          useStore().set("mainColor", response.data.mainColor);
          useStore().set("loginImage", response.data.loginImage);
          useStore().set("video", response.data.video);
          useStore().set("logoProgram", response.data.logoProgram);
          useStore().set("primaryFont", response.data.primaryFont);
          useStore().set("loginBackground", response.data.loginBackground);
          useStore().set("galleryImage", response.data.galleryImage);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      useStore().set("footerLogo", "footer-logo-demo.webp");
      useStore().set("logo", "logo-dealer-demo.webp");
      useStore().set("cscClientId", "3");
      useStore().set("mainColor", "#801C62");
      useStore().set("loginImage", "login-image-demo.webp");
      useStore().set("video", "intro-demo.mp4");
      useStore().set("logoProgram", "program-logo-demo.webp");
      useStore().set("primaryFont", "Roboto");
      useStore().set("loginBackground", "bg-demo.webp");
      useStore().set("galleryImage", "");
    }
  },
};
